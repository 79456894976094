import React from "react";
import {BlurHash} from 'react-blurhash'
import {WazeIcon} from '../../components/atoms/Icons'
import {
  Box,
  Flex,
  Heading,
  Container,
  Text,
  Divider,
  IconButton,
  Icon,
  SimpleGrid,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  useDisclosure,
  chakra,
} from "@chakra-ui/react";
import Linker from "../atoms/Linker";
import Image from "next/image";

// import {
//   FaRegEdit,
//   FaPhoneAlt,
//   FaCalendarCheck,
//   FaCogs,
//   FaWpforms,
//   FaNotesMedical,
//   FaBars,
//   FaMapMarkerAlt,
// } from "react-icons/all-files/fa";

import {FaRegEdit} from "@react-icons/all-files/fa/FaRegEdit";
import {FaPhoneAlt} from "@react-icons/all-files/fa/FaPhoneAlt";
import {FaCalendarCheck }from "@react-icons/all-files/fa/FaCalendarCheck";
import {FaCogs} from "@react-icons/all-files/fa/FaCogs";
import {FaNotesMedical} from "@react-icons/all-files/fa/FaNotesMedical";
import {FaMapMarkerAlt }from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { useRouter } from "next/router";
import DrawerComponent from "../burger-menu/drawer";
import Link from 'next/link'
const ActionBar = () => {
  const router = useRouter();

  return (
    <>
      <Flex
        position={["unset", "unset", "sticky"]}
        bottom={["unset", "unset", 0]}
        fontSize="2xl"
        height={[null, null, "130px"]}
        bg="brand.200"
        color="white"
        py={5}
        w="100%"
      >
        <SimpleGrid
          w="100%"
          justifyItems="center"
          alignItems="center"
          spacingY={3}
          columns={[1, 1, 4]}
        >
          <Flex
            cursor="pointer"
            onClick={() => router.push("/contact")}
            color="white"
            justify="center"
            align="center"
            flexDir="column"
          >
            <Icon as={FaPhoneAlt} fontSize={48} />
            <Text>Contact Us</Text>
          </Flex>
          <Divider display={["block", "block", "none"]} />
          <chakra.a
            href="https://intake.mychirotouch.com/en-US/?clinic=BC0229"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Flex
              cursor="pointer"
              justify="center"
              align="center"
              flexDir="column"
            >
              <Icon as={FaRegEdit} fontSize={48} />
              <Text>Online Forms</Text>
            </Flex>
          </chakra.a>
          <Divider display={["block", "block", "none"]} />

          <Flex
            onClick={() => router.push("/appointment")}
            cursor="pointer"
            justify="center"
            align="center"
            flexDir="column"
          >
            <Icon as={FaCalendarCheck} fontSize={48} />
            <Text>Appointments</Text>
          </Flex>
          <Divider display={["block", "block", "none"]} />
          <Flex
            onClick={() => router.push("/services-and-techniques")}
            cursor="pointer"
            justify="center"
            align="center"
            flexDir="column"
          >
            <Icon as={FaCogs} fontSize={48} />
            <Text>Services</Text>
          </Flex>
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default ActionBar;

export const MiniActionBar = (props) => {

  
  const router = useRouter();
  const CalendarCheckIcon = chakra(FaCalendarCheck)
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box display={router.asPath.includes('/admin') ? 'none' : 'block'} zIndex="999999999" position="sticky" bottom={0} {...props}>
      <Flex
        bg="rgb(255,255,255,0.3)"
        backdropFilter="blur(3px)"
        w="100%"
        py={2}
        align='center'
        justify='center'
      >
        <Container centerContent maxW="container.lg">
          <Flex align="center" justify="center" direction="row">
            <SimpleGrid
              gap={5}
              alignItems="center"
              justifyItems="center"
              columns={4}
            >
              <SimpleGrid alignItems="center" justifyItems="center" columns={1}>
                <a href="tel:(803) 520-0084">
                  {" "}
                  <IconButton icon={<FaPhoneAlt />} />
                </a>
                <Text fontSize="xs">Call</Text>
              </SimpleGrid>
              <SimpleGrid alignItems="center" justifyItems="center" columns={1}>
           <IconButton as="a"
                  cursor="pointer"
                  onClick={() => router.push("/appointment")}
                  icon={<Link passHref href="/appointment"><CalendarCheckIcon  /></Link>}
                />
                <Text fontSize="xs">Book</Text>
              </SimpleGrid>
              <SimpleGrid alignItems="center" justifyItems="center" columns={1}>
                <a
                  href="https://intake.mychirotouch.com/en-US/?clinic=BC0229"
                  target="none"
                >
                  {" "}
                  <IconButton icon={<FaNotesMedical />} />
                </a>
                <Text isTruncated={true} fontSize="xs">
                  Forms
                </Text>
              </SimpleGrid>
<Popover>
  <PopoverTrigger>
              <SimpleGrid alignItems="center" justifyItems="center" columns={1}>
                {/* <a
                  target="none"
                  href="https://www.google.com/maps/place/5076+Sunset+Blvd,+Lexington,+SC+29072/@34.0093822,-81.195933,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8978ed6b71bab:0x1923b60c807a178c!8m2!3d34.0093778!4d-81.193739"
                > */}
                  <IconButton as="a" cursor="pointer" icon={<FaMapMarkerAlt />} />
                {/* </a> */}
                <Text fontSize="xs">GPS</Text>
              </SimpleGrid>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Which app would you like to use?</PopoverHeader>
                <PopoverBody w='100%'>
                  <SimpleGrid columnGap={3} alignItems='space-evenly' justifyContent='space-evenly' justifyItems='space-evenly'w='100%'columns={3}>
                  <a target="none" href="https://maps.apple.com/?address=5076%20Sunset%20Blvd,%20Unit%20C,%20Lexington,%20SC%20%2029072,%20United%20States&auid=17030292374442269028&ll=34.009352,-81.193745&lsp=9902&q=Jason%20Mubarak%20-%20Midlands%20Spine%20Center&_ext=CjIKBQgEEOIBCgQIBRADCgQIBhByCgQIChAACgQIUhAECgQIVRAACgQIWRAGCgUIpAEQARImKQ8ni0efAEFAMbGcExe/TFTAOY38sKPFAUFAQeFFGooNTFTAUAM%3Dhttps://maps.apple.com/?address=5076%20Sunset%20Blvd,%20Unit%20C,%20Lexington,%20SC%20%2029072,%20United%20States&auid=17030292374442269028&ll=34.009352,-81.193745&lsp=9902&q=Jason%20Mubarak%20-%20Midlands%20Spine%20Center&_ext=CjIKBQgEEOIBCgQIBRADCgQIBhByCgQIChAACgQIUhAECgQIVRAACgQIWRAGCgUIpAEQARImKQ8ni0efAEFAMbGcExe/TFTAOY38sKPFAUFAQeFFGooNTFTAUAM%3D">   <SimpleGrid columns={1}>
                   <Image src='/AppleMaps.png' height={512} width={512} />
                    
                    </SimpleGrid></a>
                    <a target="none" href="https://www.google.com/maps/place/5076+Sunset+Blvd,+Lexington,+SC+29072/@34.0093822,-81.195933,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8978ed6b71bab:0x1923b60c807a178c!8m2!3d34.0093778!4d-81.193739">    <SimpleGrid columns={1}>
                      <Image src='/GoogleMaps.png' height={461} width={512} />
                    
                    </SimpleGrid></a>
  
                    <a target="none" href="https://www.waze.com/en/live-map/directions/united-states/south-carolina/lexington/midlands-spine-center?place=ChIJMcysBAmX-IgRiDnP-jDSEHk&utm_campaign=waze_website&utm_medium=website_menu&utm_source=waze_website">     <SimpleGrid columns={1}>
                    <Image src='/WazeIcon2.png' width={400} height={400} />
                    </SimpleGrid></a>
                  </SimpleGrid>
                  {/* <Flex color='blue.300' flexDir='row' justify='space-around'>
                    <Icon w='100px' h='100px' as={WazeIcon} />
                  
                   <Image src='/AppleMaps.png' width={100} height={100} objectFit='cover' objectPosition='center' />
                  </Flex> */}
                </PopoverBody>
              </PopoverContent>
              </Popover>
              
            </SimpleGrid>
            <SimpleGrid top={2} alignItems='center' justifyItems='center' pos='absolute' right={[0,0,5]}>
            <DrawerComponent 
                  display={["block", "block"]}
                  size={['md','md','md','full']}
                  onOpen={onOpen}
                  onClose={onClose}
                />
                <Text opacity={0}>Menu</Text>
                </SimpleGrid>
                <Flex pos='absolute' left={[0,0,5]} ><Image src='/favicon.png' width={50} height={50} /></Flex>
            {/* <IconButton onClick={onOpen} icon={<FaBars/>} />
     <Text fontSize='xs'>Menu</Text> */}
          </Flex>
        </Container>
      </Flex>
    </Box>
  );
};
