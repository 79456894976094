import { forwardRef, MenuButton,Flex, IconButtonProps, IconButton, chakra } from '@chakra-ui/react'

// import { FaChevronDown, FaChevronRight } from 'react-icons/fa'
import {FaChevronDown} from '@react-icons/all-files/fa/FaChevronDown';
import {FaChevronRight} from '@react-icons/all-files/fa/FaChevronRight';

export const ChevronIcon = () => {
    const ChevyDown = chakra(FaChevronDown)
    return(
        <Flex align="center" justify="center" w="100%">
        <ChevyDown alignSelf="center" justifySelf="center" />
        </Flex>
    )
}

export const ChevronIconInner = () => {
    const ChevyInner = chakra(FaChevronRight)
    return (
        <Flex align="center" justify="center" w="100%">
            <ChevyInner alignSelf="center" justifySelf="center"/>
        </Flex>
    )
}

export const MButtonInner = forwardRef<IconButtonProps, "button">((props, ref) => (
    <IconButton _hover={{bg:'transparent',}} _focus={{border:'blackAlpha.200', bg: 'transparent'}} justify="center"  as={MenuButton} bg="transparent" alignContent="center" justifyContent="center" justifyItems="center" icon={<ChevronIconInner />}  ref={ref} {...props} />
  ))

export const MButton = forwardRef<IconButtonProps, "button">((props, ref) => (
    <IconButton _hover={{bg:'transparent',}} _focus={{border:'blackAlpha.200', bg: 'transparent'}} justify="center"  as={MenuButton} bg="transparent" alignContent="center" justifyContent="center" justifyItems="center" icon={<ChevronIcon />}  ref={ref} {...props} />
  ))