import React, {useState} from 'react'
import { Box, SimpleGrid, Text,  Drawer,Link, Accordion, AccordionIcon, AccordionPanel, AccordionItem, AccordionButton, DrawerCloseButton, DrawerBody, DrawerContent, useDisclosure, DrawerHeader, Stack, Icon, DrawerOverlay } from '@chakra-ui/react'
// import { FaBars } from 'react-icons/all-files/fa/FaBars'
import { FaNotesMedical } from '@react-icons/all-files/fa/FaNotesMedical'
// import DropdownMenuButton from '../dropdown-menu-button'
import Hamburger from 'hamburger-react'
import Linker from '../atoms/Linker'



const DrawerComponent = (props, {placement, size='md', display }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [open, setOpen] = useState(isOpen)
    const activateMenu = () => {
        if(open) {
            setOpen(false)
            onClose()
        } else {
            setOpen(true)
            onOpen()
        }
    }

    const closeMenu = () => {
        setOpen(false)
        onClose()
    }

    const MenuLink = ({href, title}) => {
        return(
            <Linker href={href} onClick={closeMenu} title={title} />
        )
    }
    
    
    return (
       <Box {...props}>
            <SimpleGrid  display={display} alignSelf='start' justifySelf='center' columns={1} alignItems='center' justifyItems='center'>
                
          <Hamburger distance='sm'  toggled={isOpen} toggle={() => activateMenu()} /> 
          
           
            <Drawer  onClose={onClose} isOpen={isOpen} placement={placement} size={size}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Midlands Spine Center <br/> <Text fontSize='xs'>Navigation Menu</Text></DrawerHeader>
                    <DrawerBody bg='gray.300'>
                        <Stack>
                        <MenuLink  href="/" title="Home" />
                    
                       <MenuLink  href="/appointment" title="Appointment" />
                    <LayeredMenu title="New Patient Center">
                        {/* <Linker href="new-patient-center" title="New Patient Center" /> */}
                        <Stack>
                        <Link fontWeight='bold'  target='none' href="https://intake.mychirotouch.com/en-US/?clinic=BC0229">Online Forms <Icon as={FaNotesMedical} /></Link>
                       
                        <LayeredMenu title='What to Expect'>
                            <Stack>
                            <MenuLink fontWeight='bold' href='your-first-visit' title="Your first visit"/>
                            <MenuLink href="/phase-1:-relief-care" title=" Phase 1: Relief Care" />
                            <MenuLink href="/phase-2:-corrective-care" title="Phase 2: Corrective Care" />
                            <MenuLink href="/phase-3:-wellness-care" title="Phase 3: Wellness Care" />
                            </Stack>
                        </LayeredMenu>
                        </Stack>
                        <Stack>
                        <MenuLink href="/health-resources" title="Health Resources" />
                        <MenuLink href="payment-options" title="Payment Options" />
                        </Stack>
                    </LayeredMenu>
                    <LayeredMenu title="About Us">
                        <Stack>
                        <MenuLink href="/about-us" title="About Us" />
                        <MenuLink href="/meet-the-team" title="Meet the Team" />
                        <MenuLink href="/services-and-techniques" title="Services & Techniques" />
                        <MenuLink href="/treatments/knee_on_trac" title="Knee Decompression Therapy w/ Knee on Trac" />
                        </Stack>
                    </LayeredMenu>
                       <MenuLink href="/testimonials" title="Testimonials" />
                       <LayeredMenu title="Contact">
                           <Stack>
                               <MenuLink href="/contact" title="Contact Us" />
                               <MenuLink href="/appointment" title="Book Appointment" />
                           </Stack>
                       </LayeredMenu>
                       <MenuLink href="/blog" title="Health & Wellness Blog" />
                       <MenuLink href="/community-content" title="Community Content" />
                       </Stack>
                      {/* <SimpleGrid gap={2}columns={2}>
                          <DropdownMenuButton />
                          <DropdownMenuButton />
                          <DropdownMenuButton />
                            <DropdownMenuButton />
                      </SimpleGrid> */}
                        
                    </DrawerBody>
                    {/* <DrawerFooter></DrawerFooter> */}
                </DrawerContent>
            </Drawer>
            </SimpleGrid>
            </Box>
    )
}


const LayeredMenu = ({children, title}) => {
    return(
        <Accordion py={1} allowToggle >
            
            <AccordionItem>
                <AccordionButton>
                <Text fontWeight='bold' css={{WebkitTextStroke: '.1px blue'}} display='inline'>{title}</Text>
                <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
               {children}
                </AccordionPanel>
            </AccordionItem>
            
        </Accordion>
    )
}

export default DrawerComponent
