import React from 'react'
import { Box,Wrap, WrapItem, Container, Flex, Heading, Text, Stack, HStack, VStack, Divider } from '@chakra-ui/react'
import Image from 'next/image'
import Linker from '../atoms/Linker'
import classes from './footer.module.css'
import { useRouter } from 'next/router'
const Footer = (props) => {
    const router = useRouter()
    return (
        <>
        <Flex display={router.asPath.includes('/admin') ? 'none' : 'flex'} flexDir="column" w="100%">
        <Container pt={5} maxW="container.lg">
        <Image className={classes.footerLogo} src="/midlands-spine-logo.png" width={242} height={73} />
        </Container>
        <Flex justify="center" w="100%">
            <Flex justify="center" w="90%">
        <Wrap pt={10} spacing={4}>
            <WrapItem>
                
            <Linker textTransform="uppercase" title="COPYRIGHT &copy; 2021 Sublime Solutions, L.L.C." href="https://sublimesolutions.tech" inactiveColor="blue" activeColor="blue" fontSize="xs" /> 
          
           
            </WrapItem>

            <WrapItem>
            <Linker textTransform="uppercase" title="ADMIN LOG IN" href="/admin" inactiveColor="blue" activeColor="blue" fontSize="xs" /> 
            </WrapItem>

            <WrapItem>
                <Linker textTransform="uppercase" title="site map" href="/sitemap.xml" fontSize="xs" activeColor="blue" inactiveColor="blue" /> 
            </WrapItem>
            <WrapItem>
                <Linker textTransform="uppercase" title="Privacy Policy" href="/" fontSize="xs" activeColor="blue" inactiveColor="blue" />
            </WrapItem>
            <WrapItem>
                <Linker textTransform="uppercase" title="Terms of Use" href="/" fontSize="xs" activeColor="blue" inactiveColor="blue" />
            </WrapItem>
        </Wrap>
        </Flex>
        </Flex>
        </Flex>
        </>
    )
}

export default Footer
