import React, { useEffect } from 'react'
import * as fbq from '../components/seo/Pixel'
import { ChakraProvider } from '@chakra-ui/react'
import { Header } from '../components/header'
// import Head from 'next/head'
// import Banner from '../components/banner'
import Footer from '../components/footer'
import { useRouter } from 'next/router'
import theme from '../styles/theme.js'
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
import "../styles/map.css"
import { createGlobalStyle } from 'styled-components'
import Script from 'next/script'
// import settings from '../settings/settings-development'
// import { useJsApiLoader } from '@react-google-maps/api'
// import dynamic from 'next/dynamic'
// import Schema from '../components/schema'
import 'react-phone-number-input/style.css'
import "react-datepicker/dist/react-datepicker.css"
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
// import '@szhsin/react-menu/dist/index.css'
// import '@szhsin/react-menu/dist/theme-dark.css'
// import '@szhsin/react-menu/dist/core.css'
// import '@szhsin/react-menu/dist/transitions/slide.css';
// import '@szhsin/react-menu/dist/transitions/slide.css';
import  { MiniActionBar } from '../components/molecules/actionbar'
// import '@inovua/reactdatagrid-community/index.css'
// import Lightbox from '../components/lightbox'
import '../styles/globals.css'
import { SessionProvider } from 'next-auth/react'
import * as gtag from '../lib/gtag'

// import 'devextreme/dist/css/dx.light.css';
const GlobalStyle = createGlobalStyle`

  body > #__next > .container {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  body.is-map > #__next > .navbar {
    display: none;
  }

  body.is-map > #__next > .container {
    width: 100%;
    max-width: 100%;
    padding: 0 !important;
  }

`

const CustomMenu = () => (
  <ul className="menu">
    <li>Login</li>
    <li>Register</li>
    <li>Open Profile</li>
  </ul>
);


function MyApp({ Component, pageProps}) {

  const router = useRouter()
  useEffect(() => {
    fbq.pageview()
    const handleRouteChange = (url) => {
      gtag.pageview(url)
      fbq.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: settings?.googleMaps?.apiKey
  // })
  // const Map = dynamic(() => import("../components/molecules/location-map/WorkingMap"), {
  //   loading: () => <Flex h="100vh" w="100%"><Spinner size="xl" /></Flex>,
  //   ssr: false
  // })

  // const MapWrapper = dynamic(() => import('../components/molecules/location-map/MapWrapper', {
  //   loading: () => <Flex h='10vh' w='100%'><Spinner size='xl' /></Flex>
  // }))



  return(
    <>
    {/* <Portal> */}
          {/* Global Site Code Pixel - Facebook Pixel */}
          <Script id='fb-p-script'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.PIXEL_ID});
          `,
        }}
      />
<Script id="g-ads-uno" strategy='afterInteractive' src="https://www.googletagmanager.com/gtag/js?id=AW-10792915568"/>
      <Script id="g-ads-setup" strategy="afterInteractive" dangerouslySetInnerHTML={{
        __html: `

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-10792915568');

        `
      }} />
    <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      {/* </Portal> */}
 <SessionProvider session={pageProps.session}  >
           <ChakraProvider theme={theme} >
             {/* <Schema /> */}
             {/* <Script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCID_-TNomGR_Au7_gvv8r7P11ldBHpubU&callback=initMap"
        strategy="lazyOnLoad"
      /> */}
          {/* <Script src="https://polyfill.io/v3/polyfill.min.js?features=default" strategy="beforeInteractive" /> */}
          
         
             <Header />
        
             <Component {...pageProps} />
          
             <MiniActionBar  />
            <Footer />
          </ChakraProvider> 
          </SessionProvider>
          </>
        )
}

export default MyApp


