import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({

    colors: {
        brand: {
            100: "#6d6b6e",
            200: "#1c449c",
            300: "#0c1e46",
            400: "#00adef",
            500: "#0384d7"
        },
        bgb: {
            100: "rgb(109,107,110,0.2)",
            200: "rgb(28,68,156,0.2)",
            300: "rgb(12,30,70, 0.2)",
            400: "rgb(0,173,239,0.5)"
        }
    },
    layerStyles: {
        menuButton: {
            bgGradient: 'linear(to-b,blue.500, blue.700)',
            
        

        }
    },
    // components: {
    //     Button: {
    //         baseStyle: {
    //             fontWeight:'bold'
    //         },
    //         variants: {
    //             'with-shadow': {
    //               bg: 'blue.500',
    //               boxShadow: '0 0 2px 2px #efdfde',
    //             },
    //             // 4. We can override existing variants
    //             solid: (props) => ({
    //               bg: props.colorMode === 'dark' ? 'blue.300' : 'blue.400',
    //             }),
    //           },
    //     },
    //     MenuButton: {
    //         baseStyle: {
    //             fontWeight: 'bold'
    //         },
    //         variants: {
    //             'with-shadow': {
    //               bg: 'red.400',
    //               boxShadow: '0 0 2px 2px #efdfde',
    //             },
    //             // 4. We can override existing variants
    //             solid: (props) => ({
    //               bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
    //             }),
    //           },
    //     }
    // }
})

export default theme;