import React, { useState, useEffect, useRef } from "react";
import { ChevronIcon, MButton, MButtonInner } from "./header-components";
import {
  Box,
  Flex,
  Heading,
  ButtonGroup,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  Text,
  Stack,
  Portal,
  Center,
  SimpleGrid,
  Container,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuIcon,
  MenuCommand,
  forwardRef,
  chakra,
  MenuButtonProps,
  MenuDivider,
  VStack,
  Button,
  Icon,
  IconButton,
  Spacer,
  List,
  ListItem,
  useMenuButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
// import {
//   FaEnvelope,
//   FaMapMarkerAlt,
//   FaPhoneAlt,
//   FaChevronDown,
//   FaBars,
//   FaChevronRight,
//   FaCalendarTimes,
//   FaCalendarCheck,
//   FaWpforms,
//   FaNotesMedical,
//   FaPhone,
// } from "react-icons/all-files/fa";
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import {FaPhoneAlt} from "@react-icons/all-files/fa/FaPhoneAlt";
import {FaChevronDown} from '@react-icons/all-files/fa/FaChevronDown';
import {FaBars} from '@react-icons/all-files/fa/FaBars';
import {FaCalendarCheck} from '@react-icons/all-files/fa/FaCalendarCheck';
import { FaNotesMedical } from "@react-icons/all-files/fa/FaNotesMedical";

import Link from "next/link";
import { IoDocumentTextSharp } from "react-icons/io5";
import Image from "next/image";
import Linker from "../atoms/Linker";
import { useRouter } from "next/dist/client/router";

export const Header = (props) => {
  const router = useRouter();
  const MenuIcon = chakra(FaBars);
  const MailIcon = chakra(FaEnvelope);
  const MapIcon = chakra(FaMapMarkerAlt);
  const PhoneIcon = chakra(FaPhoneAlt);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        display={router.asPath.includes('/admin') ? 'none' : ["none", "none", "none", "block"]}
        zIndex={"popover"}
        id="header"
        
      >
        <Flex flexDir="column" w="100%" bg="blackAlpha.200">
          <Container w="100%" maxW="container.lg">
            <Flex py={2} w="100%" flexDir="row">
              <Flex w="100%" flexDir="row">
                <Image src="/midlands-spine-logo.png" width={242} height={73} />
        
              </Flex>
              <Flex
                justifySelf="flex-end"
                w="100%"
                align="center"
                justify="flex-end"
              >
                <ButtonGroup isAttached={true}>
                <SimpleGrid alignItems="center" justifyItems="center">
                    <a
                      target="none"
                      href="https://www.google.com/maps/place/5076+Sunset+Blvd,+Lexington,+SC+29072/@34.0093822,-81.195933,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8978ed6b71bab:0x1923b60c807a178c!8m2!3d34.0093778!4d-81.193739"
                    >
                      {" "}
                      <IconButton icon={<FaMapMarkerAlt />} />
                    </a>
                    <Text fontSize="xs">Find</Text>
                  </SimpleGrid>
                  <SimpleGrid alignItems="center" justifyItems="center">
                    <a href="tel:803-520-0084">
                      {" "}
                      <IconButton icon={<FaPhoneAlt />} />{" "}
                    </a>
                    <Text fontSize="xs">Call</Text>
                  </SimpleGrid>
 
                  <SimpleGrid alignItems="center" justifyItems="center">
                    <a
                      target="none"
                      href="https://intake.mychirotouch.com/en-US/?clinic=BC0229"
                    >
                      {" "}
                      <IconButton icon={<FaNotesMedical />} />
                    </a>
                    <Text fontSize="xs">Forms</Text>
                  </SimpleGrid>
   
                  <SimpleGrid alignItems="center" justifyItems="center">
                    <IconButton
                      _hover={{ color: "blue.600" }}
                      onClick={() => router.push("/appointment")}
                      icon={<FaCalendarCheck />}
                    />
                    <Text fontSize="xs">Book</Text>
                  </SimpleGrid>

                </ButtonGroup>
              </Flex>
            </Flex>
          </Container>
          <Flex color="white" bg="blue.700" justify="center" flexDir="row">
            <HStack py={1}>
              <Button
                size="sm"
                bg="transparent"
                _hover={{ bg: "blue.500" }}
                _focus={{ bg: "blue.500" }}
                onClick={() => router.push("/")}
              >
                Home
              </Button>
              <Menu>
                <MenuButton
                  size="sm"
                  as={Button}
                  _hover={{ bg: "blue.500" }}
                  _focus={{ bg: "blue.500" }}
                  bg="transparent"
                  rightIcon={<FaChevronDown />}
                >
                  New Patient Center
                </MenuButton>
                <MenuList zIndex="popover" bg="blue.700">
                  <MyMenuItem href="/new-patient-center">
                    New Patient Center
                  </MyMenuItem>
                  <MenuDivider />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://intake.mychirotouch.com/en-US/?clinic=BC0229"
                  >
                    <MenuItem
                      bg="blue.700"
                      _hover={{ bg: "blue.500" }}
                      _focus={{ bg: "blue.500" }}
                    >
                      Online Forms
                    </MenuItem>
                  </a>
                  <MenuDivider />
                  <MenuGroup
                    cursor="pointer"
                    onClick={() => router.push("/what-to-expect")}
                    title="What to Expect"
                  >
                    <MyMenuItem href="/your-first-visit">
                      Your First Visit
                    </MyMenuItem>
                    <MyMenuItem href="/phase-1:-relief-care">
                      Phase 1: Relief Care
                    </MyMenuItem>
                    <MyMenuItem href="/phase-2:-corrective-care">
                      Phase 2: Corrective Care
                    </MyMenuItem>
                    <MyMenuItem href="/phase-3:-wellness-care">
                      Phase 3: Wellness Care
                    </MyMenuItem>
                  </MenuGroup>
                  <MenuDivider />
                  <MyMenuItem href="/health-resources">
                    Health Resources
                  </MyMenuItem>
                  <MyMenuItem href="/payment-options">
                    Payment Options
                  </MyMenuItem>
                </MenuList>
              </Menu>

            
              <Menu>
                <MenuButton
                  size="sm"
                  as={Button}
                  _hover={{ bg: "blue.500" }}
                  _focus={{ bg: "blue.500" }}
                  bg="transparent"
                  rightIcon={<FaChevronDown />}
                >
                  About Us
                </MenuButton>
                <MenuList zIndex="popover" bg="blue.700">
                  <MyMenuItem href="/about-us">About Us</MyMenuItem>
                  <MyMenuItem href="/meet-the-team">Meet the Team</MyMenuItem>
                  <MyMenuItem href="/services-and-techniques">
                    Services & Techniques
                  </MyMenuItem>
                  <MyMenuItem href="/treatments/knee_on_trac">
                    Knee Decompression Therapy w/ Knee on Trac</MyMenuItem> 
                </MenuList>
              </Menu>
         
                <Button
                  size="sm"
                  bg="transparent"
                  _hover={{ bg: "blue.500" }}
                  _focus={{ bg: "blue.500" }}
                  onClick={() => router.push("/conditions-treated")}
                >
                  Conditions Treated
                </Button>
          
              <Button
                size="sm"
                bg="transparent"
                _hover={{ bg: "blue.500" }}
                _focus={{ bg: "blue.500" }}
                onClick={() => router.push("/testimonials")}
              >
                Testimonials
              </Button>
              <Menu>
                <MenuButton
                  size="sm"
                  as={Button}
                  _hover={{ bg: "blue.500" }}
                  _focus={{ bg: "blue.500" }}
                  bg="transparent"
                  rightIcon={<FaChevronDown />}
                >
                  Contact
                </MenuButton>
                <MenuList zIndex="popover" bg="blue.700">
                  <MyMenuItem href="/contact">Contact Us</MyMenuItem>
                  <MyMenuItem href="/appointment">
                    Request Appointment
                  </MyMenuItem>
                </MenuList>
              </Menu>
              <Button
                size="sm"
                bg="transparent"
                _hover={{ bg: "blue.500" }}
                _focus={{ bg: "blue.500" }}
                onClick={() => router.push("/blog")}
              >
                Blog
              </Button>
              <Button
                size="sm"
                bg="transparent"
                _hover={{ bg: "blue.500" }}
                _focus={{ bg: "blue.500" }}
                onClick={() => router.push("/community-content")}
              >
                Community Content
              </Button>
            </HStack>
            {/* </Container> */}
          </Flex>
        </Flex>
      </Box>
      <Box
      display={router.asPath.includes('/admin') ? 'none' : ["block", "block", "block", "none"]}
       
        zIndex="docked"
        position="-webkit-sticky"
        w="100%"
        bg="black"
      >
        <Flex align="center" justify="center" h="60px" bg="brand" w="100%">
          <Flex w="100%" h="100%">
            {/*       
      <Spacer /> */}
            <Heading
              onClick={() => router.push("/")}
              as="span"
              ml={5}
              fontWeight="bold"
              color="whiteAlpha.900"
              alignSelf="center"
              fontSize={["15px"]}
            >
              Midlands Spine Center
            </Heading>
            <Spacer />
            <Heading
              alignSelf="center"
              color="yellow.600"
              fontSize="sm"
            ></Heading>
            <Spacer />
            <HStack mr={2} spacing={0}>
              <a href="#contact-us">
                {" "}
                <IconButton
                  _hover={{ bg: "transparent", color: "whiteAlpha.800" }}
                  size="md"
                  bg="transparent"
                  color="white"
                  icon={<MailIcon fontSize="lg" />}
                />{" "}
              </a>
              <a href="#location">
                {" "}
                <IconButton
                  _hover={{ bg: "transparent", color: "whiteAlpha.800" }}
                  size="md"
                  bg="transparent"
                  color="white"
                  icon={<MapIcon fontSize="lg" />}
                />
              </a>
              <a href="tel:(803) 520-0084">
                <IconButton
                  _hover={{ bg: "transparent", color: "whiteAlpha.800" }}
                  size="md"
                  bg="transparent"
                  color="white"
                  icon={<PhoneIcon fontSize="lg" />}
                />
              </a>
            </HStack>

            {/* <IconButton onClick={onOpen} alignSelf="center" bg="transparent"  size="lg" color="white" flex={true} justifySelf="center"icon={<MenuIcon fontSize="2xl" />}/> */}
          </Flex>
        </Flex>
      </Box>
      {/* <Drawer bg="green" size="full" placement="right" onClose={onClose} isOpen={isOpen}>
   
      <DrawerOverlay />
      <DrawerContent bg="blue.500">
      <DrawerCloseButton />
        <DrawerHeader borderBottomWidth="1px">Midlands Spine Center</DrawerHeader>
        <DrawerBody>
          <List spacing={2} >
            <ListItem onClick={onClose}>
            <Linker title="Home" href="/" />
            </ListItem>
            <Divider />
            <ListItem >
            <Accordion outline="0px" allowToggle>
              <AccordionItem  p={0} border="0px" > 
                
                  <AccordionButton alignItems="center" outline="0px" fontWeight="bold" p={0}>
                  New Patient Center
                  <AccordionIcon />
                  
                  </AccordionButton>
               <AccordionPanel>
                 <VStack align="start">
                   <Linker onClick={() => onClose()} href="/new-patient-center" title="New Patient Center"/>
                   <Divider />
                   <Linker onClick={() => onClose()} href="https://intake.mychirotouch.com/en-US/?clinic=BC0229" title="Online Forms" />
                   <Divider />
                   <Box>
                   <Linker onClick={() => onClose()} fontWeight="regular" display="block" href="what-to-expect" title="What to Expect" />
                   <Linker onClick={() => onClose()} display="block" ml={5} href="your-first-visit" title="Your First Visit"/>
                   <Linker onClick={() => onClose()} display="block" ml={5} href="/phase-1:-relief-care" title="Phase 1: Relief Care" />
                   <Linker onClick={() => onClose()} display="block" ml={5} href="/phase-2:-corrective-care" title="Phase 2: Corrective Care" />
                   <Linker onClick={() => onClose()} display="block" ml={5} href="/phase-3:-wellness-care" title="Phase 3: Wellness Care" />
                   </Box>
                 </VStack>
               </AccordionPanel>
              </AccordionItem>
            </Accordion>
            </ListItem>
            <Divider />
            <ListItem >
            <Accordion outline="0px" allowToggle>
              <AccordionItem  p={0} border="0px" > 
                
                  <AccordionButton fontWeight="bold" alignItems="center" outline="0px" p={0}>
                  About Us
                  <AccordionIcon />
                  </AccordionButton>
               <AccordionPanel>
                 <VStack align="start">
                   <Linker onClick={() => onClose()} href="/about-us" title="About Us"/>
                   <Linker onClick={() => onClose()} href="/meet-the-team" title="Meet the Team" />
                   <Linker onClick={() => onClose()} href="/services-and-techniques" title="Services & Techniques" />
                 </VStack>
               </AccordionPanel>
              </AccordionItem>
            </Accordion>
            </ListItem>
            <Divider />
            <ListItem>
              <Linker onClick={() => onClose()} href="/testimonials" title="Testimonials"/>
            </ListItem>
            <Divider/>
            <ListItem>
            <Accordion outline="0px" allowToggle>
              <AccordionItem  p={0} border="0px" > 
                
                  <AccordionButton alignItems="center" outline="0px" fontWeight="bold" p={0}>
                  Contact Us
                  <AccordionIcon />
                  
                  </AccordionButton>
               <AccordionPanel>
                 <VStack align="start">
                   <Linker onClick={() => onClose()} href="/contact" title="Contact"/>
                  
                   <Linker onClick={() => onClose()} href="/appointment" title="Request Appointment" />
                  
                   
                 </VStack>
               </AccordionPanel>
              </AccordionItem>
            </Accordion>
            </ListItem>
            <Divider />
            <ListItem>
              <Linker onClick={() => onClose()} href="/blog" title="Blog"/>
            </ListItem>
            <Divider />
            <ListItem>
              <Linker onClick={() => onClose()} href="/community-content" title="Community Content"/>
            </ListItem>
            </List>
        </DrawerBody>
      </DrawerContent>
    </Drawer> */}
    </>
  );
};

export const CustomMenu = ({
  buttonTitle,
  buttonLink,
  href1,
  href2,
  href3,
  href4,
  title1,
  title2,
  title3,
  title4,
}) => {
  const menuRef = React.createRef();
  const router = useRouter();
  return (
    <Menu>
      <CustomMenuButton title={buttonTitle} href={buttonLink} />
      <MenuList>
        <MenuItem
          onClick={() =>
            router.push("https://intake.mychirotouch.com/en-US/?clinic=BC0229")
          }
        >
          <Link passHref href={href1}>
            {title1}
          </Link>
        </MenuItem>
        <MenuItem>
          <Menu>
            <CustomMenuButtonInner
              title={"What to Expect"}
              href="/new-patient-center/what-to-expect"
            />
          </Menu>
        </MenuItem>
        <MenuItem onClick={() => router.push(href3)}>
          <Link passHref href={href3}>
            {title3}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link passHref href={href4}>
            {title4}
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export const CustomMenuButton = ({ title, href }) => {
  return (
    <>
      <HStack spacing="3px">
        <Link passHref href={href}>
          <a>{title}</a>
        </Link>

        <MButton />
      </HStack>
    </>
  );
};
export const CustomMenuButtonInner = ({ title, href }) => {
  return (
    <>
      <HStack spacing="3px">
        <Link passHref href={href}>
          <a>{title}</a>
        </Link>

        <MButtonInner />
      </HStack>
    </>
  );
};

export const MenuLink = React.forwardRef(({ href, title, ref }) => (
  <>
    <HStack spacing="3px">
      <Link passHref href={href}>
        <a>{title}</a>
      </Link>

      <LinkButton ref={ref} />
    </HStack>
  </>
));

export const LinkButton = React.forwardRef((props, ref) => (
  <IconButton
    _hover={{ bg: "transparent", color: "blackAlpha.900" }}
    variant="ghost"
    h="20px"
    size="sm"
    as={FaChevronDown}
    ref={ref}
  />
));

export const MyMenuItem = ({
  hoverBg = "blue.500",
  focusBg = "blue.500",
  href = "/",
  color = "",
  bg = "blue.700",
  children = "Menu Item",
}) => {
  const router = useRouter();
  return (
    <MenuItem
      _hover={{ bg: hoverBg }}
      color={color}
      bg={bg}
      _focus={{ bg: focusBg }}
      onClick={() => router.push(href)}
    >
      {children}
    </MenuItem>
  );
};

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <Box p="1">
    <Tag ref={ref} {...rest}>
      {children}
    </Tag>
  </Box>
));

const CustomToolTip = () => (
  <Tooltip label="Hover me">
    <CustomCard>Tag Here</CustomCard>
  </Tooltip>
);
