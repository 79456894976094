import React from 'react'
import NextLink from 'next/link'
import { Link, Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'

const Linker = ({title, props, fontWeight="bold", display, href, ml, mr, pt, py, px, pb, pl, pr, activeColor="blue.400", inactiveColor="black", fontSize, textTransform, onClick}) => {

    const router = useRouter()
    const isActive = router.pathname === href
    return (
       <Box w='fit-content' onClick={onClick}>
        <NextLink  href={href} passHref>
            <Link {...props}  textTransform={textTransform} fontSize={fontSize} display={display} ml={ml} mr={mr} pt={pt} py={py} px={px} pb={pb} pl={pl} pr={pr} fontWeight={fontWeight} color={isActive ? activeColor : inactiveColor}>{title}</Link>
        </NextLink>
        </Box>
    
    )
}

export default Linker
